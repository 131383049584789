import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getOfferPublic } from '../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import "./OfferCard.css"
import ImagesSkillSlider from '../../ManageAllOffers/DetailedOfferScreen/ImagesSkillSlider/ImagesSkillSlider'
import { Offer } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import "./OfferCard.css"
import { getProfilePictureByUserId, getUserDataById, UserValidData } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faStar } from '@fortawesome/pro-solid-svg-icons'
import { getStars } from '../../../utils/numbers/numbers'
import LabelJobDate from './LabelJobDate/LabelJobDate'
import { orderSchedulesByStartDate } from '../../../utils/dates/dates'
import { DefaultPosition, distanceInKmBetweenEarthCoordinates } from '../../../utils/maps/locations'
import WorkPreferenceIcons from './WorkPreferenceIcons/WorkPreferenceIcons'
import Price from '../../UIcomponents/Price/Price'

export type UserCoordinates = {
	latitude: number;
	longitude: number;
};


type OfferCardsProps = {
    id: string
    userPosition?: UserCoordinates
    onPress: (id: string) => void
}

type UserData = {
    firstName: string
    lastName: string
    gimmerScore: number
    gimScore: number
    numberReviews: {
        gim: number,
        gimmer: number
    }
}

const OfferCard = ({ id, userPosition, onPress }: OfferCardsProps) => {

    const [offer, setOffer] = useState<Offer | null>(null)
    const [imgUrl, setImgUrl] = useState("")
    const [userData, setUserData] = useState<UserData>({
        firstName: "",
        lastName: "",
        gimmerScore: 0,
        gimScore: 0,
        numberReviews: {
            gim: 0,
            gimmer: 0
        }
    })

    const firstDate = useMemo(() => orderSchedulesByStartDate(offer?.detailsSchedule)[0]?.start ?? offer?.timestamp, [offer?.detailsSchedule[0]?.start])

    useEffect(() => {
      
        const getData = () => {
            getOfferPublic(id)
            .then((offer) => {

                const Offer: Offer = {
                    ...offer,
                    type: offer.estimatedTime ? "Task" : "Job",
                    timestampMilliseconds: new Date(offer.timestamp).getTime(),
                    seen: Boolean(offer.seen)
                }
                setOffer(Offer)
                
                getProfilePictureByUserId(offer.userId, "small")
                .then((imgUrl) => {
                  setImgUrl(imgUrl)
                }).catch(() => {})

                getUserDataById(offer.userId, { firstName: 1, lastName: 1, gimmerScore: 1, gimScore: 1, numberReviews: 1 })
                .then((userInfo: any) => {
                    setUserData({
                        firstName: userInfo.firstName ?? "",
                        lastName: userInfo.lastName ?? "",
                        gimmerScore: getStars(userInfo.gimmerScore) ?? "",
                        gimScore: getStars(userInfo.gimScore) ?? "",
                        numberReviews: {
                            gim: userInfo.numberReviews.gim,
                            gimmer: userInfo.numberReviews.gimmer
                        }
                    })
                }).catch(() => {})
                
            }).catch(() => {

            })
        }
    
        getData()

    }, [])

    const setHeightLabelJobName = useCallback(
        (n: number) => {
            
        },
        [],
    )
    

    const offerType = offer?.estimatedTime ? "Task" : "Job"

    if(!offer)
        return (<></>)

    let distanceKm = 0;
    if (offer.workPreference?.outside?.address) {
        distanceKm = distanceInKmBetweenEarthCoordinates(
            userPosition?.latitude ?? DefaultPosition.latitude,
            userPosition?.longitude ?? DefaultPosition.longitude,
            offer.workPreference.outside.latitude,
            offer.workPreference.outside.longitude,
        );
    }

    return (
        <div onClick={() => onPress(id)} className="offer-card flex flex-d-column pointer">

            <div className="header-offer-card p-relative flex flex-ai-center pr-6">

                <div className="p-relative con-circle-user-img">
                    <div className="circle-user-img p-absolute circle center">
                        <img className="circle user-img" src={imgUrl} alt="user" />
                    </div>
                </div>

                <p className="fs-24-vh fw-500 ml-6 color-text flex-1">{userData.firstName}</p>

                <div className="flex flex-ai-center ml-8">
                    <FontAwesomeIcon icon={faStar} className="color-alert fs-20-vh" />
                    <div className="flex flex-ai-end height-100">
                        <p className="fs-20-vh color-text fw-600 ml-4">{offerType === "Task" ? userData.gimmerScore : userData.gimScore}</p>
                        <p className="fs-10-vh color-text fw-500 ml-2">({offerType === "Task" ? userData.numberReviews.gimmer : userData.numberReviews.gim})</p>
                    </div>
                </div>

            </div>

            <div className="body-offer-card p-relative">
                <ImagesSkillSlider 
                    id={offer.userId}
                    type={offerType}
                    jobId={offer.jobId}
                    imgKey={offer.imgKey}
                />
                <LabelJobDate jobId={offer.jobId} date={firstDate} />
            </div>

            <div className="footer-offer-card flex pl-10 pr-10">
                <WorkPreferenceIcons
					workPreference={offer.workPreference}
					distanceKm={distanceKm}
				/>
                {
                    offer.estimatedTime &&
                    <div className="flex flex-d-column">
                        <div className="flex flex-ai-center">
                            <FontAwesomeIcon icon={faClock} className="color-text fs-20-vh"/>
                            <p className="fs-20-vh color-text fw-600 ml-6">{offer.estimatedTime}</p>
                        </div>
                        <p className="fs-10-vh color-text mt-4 fle-1">{"Time needed"}</p>
                    </div>
                }

                <div className="center flex-d-column ml-6">
                    <Price 
                        otherUserID={offer.userId}
                        type={offer.type === "Task" ? "gim" : "gimmer"}
                        jobId={offer.jobId}
                        categoryName={offer.categoryName}
                        estimatedTime={offer.estimatedTime}
                        cssClasses={"fs-18 color-text fw-600"}
                        initText={"$"}
                        isPublic={true}
                    />
                    <p className="fs-11 color-text">{offer.type === "Task" ? "Earn" : "Cost"}</p>
                </div>
            </div>

        </div>
    )
}

export default React.memo( OfferCard )