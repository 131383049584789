
import { FaHouseUser, FaLaptop, FaWalking } from 'react-icons/fa'
import React from 'react'
import { WorkPreference } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'

type WhereIconsSeparatedProps = {
    workPreference: WorkPreference
    size: string
}

const WhereIconsSeparated = ({ workPreference, size }: WhereIconsSeparatedProps) => {

    const { outside, inside, remote } = workPreference

    const style = { width: size, height: size }

  return (
    <div className="width-100 flex flex-jc-sb">

        {
            remote &&
            <div style={style} className="center circle c-white fs-14 dark-gray-bg"><FaLaptop /></div>
        }

        {
            inside.address &&
            <div style={style} className="center circle c-white fs-14 dark-gray-bg"><FaHouseUser /></div>
        }

        {
            outside.address &&
            <div style={style} className="center circle c-white fs-14 dark-gray-bg"><FaWalking /></div>
        }

    </div>
  )
}

export default React.memo( WhereIconsSeparated )