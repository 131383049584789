import React, { useCallback, useState } from 'react'
import "./SearchSelectors.css"
import JobType from './JobType/JobType'
import When from './When/When'
import Where from './Where/Where'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import { SearchType, setOptionDisplayedSearch } from '../../../redux/slices/components/HomeOffers/homeOffersSlice'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import useGetJobName from '../../hooks/useGetJobName/useGetJobName'
import ClickOutside from '../../UIcomponents/ClickOutside/ClickOutside'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { capitalizeAllWords } from '../../../utils/strings/strings'
import WhereIconsSeparated from '../../UIcomponents/WhereIconsSeparated/WhereIconsSeparated'



const SearchSelectors = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const optionDisplayed = useSelector((state: RootState) => state.homeOffers.optionDisplayed)
    const page = useSelector((state: RootState) => state.homeOffers.page)
    const limit = useSelector((state: RootState) => state.homeOffers.limit)
    const jobId = useSelector((state: RootState) => state.homeOffers.jobId)
    const where = useSelector((state: RootState) => state.homeOffers.where)
    const startTime = useSelector((state: RootState) => state.homeOffers.when?.startTime)
    const endTime = useSelector((state: RootState) => state.homeOffers.when?.endTime)
    const type = useSelector((state: RootState) => state.homeOffers.type)
    const optionSelectedWhere = useSelector((state: RootState) => state.homeOffers.optionSelectedWhere)

    const jobName = useGetJobName(jobId ?? "")
    

    const onPressOption = (newOption: SearchType) => {

        if(newOption === SearchType.JOB){

        }else if(newOption === SearchType.WHERE){
            
        }else{
            
        }

        dispatch( setOptionDisplayedSearch(newOption) )
    }

    const onCloseOptions = useCallback(
        () => {
            dispatch( setOptionDisplayedSearch(undefined) )
        },
        [],
    )

    const onSearch = () => {
        const params: any = {
            page: page.toString(),
            limit: limit.toString(),
            type,
        }

        if(jobId)
            params["jobId"] = jobId?.toString()

        if( startTime )
            params["startTime"] = startTime?.iso

        if( endTime )
            params["endTime"] = endTime?.iso

        if( where.outside && optionSelectedWhere.includes("outside") )
            params["outside"] = JSON.stringify(where.outside)

        if( where.inside && optionSelectedWhere.includes("inside") )
            params["inside"] = JSON.stringify(where.inside)

        if( optionSelectedWhere.includes("remote"))
            params["remote"] = JSON.stringify(true)

        const searchParams = createSearchParams(params);

        navigate({
            pathname: '/',
            search: searchParams.toString(),
        });
    }

    const whenText = startTime ? 
                        `${capitalizeAllWords(moment(startTime.iso).format("D MMM")).replace(/\./, "")}${endTime ? ` - ${capitalizeAllWords(moment(endTime.iso).format("D MMM").replace(/\./, ""))}` : ""}` 
                        : ""

    return (
        <div className="selector-rearch flex p-relative">
            <button id="job" onClick={() => onPressOption(SearchType.JOB)} className={`search-option center flex-d-column ${optionDisplayed === SearchType.JOB ? "sp-selected" : ""}`}>
                <p className="fs-16-vh c-text">{"Job type"}</p>
                {
                    jobId
                    ? <p className="fs-16-vh c-text fw-600 mt-4">{jobName}</p>
                    : <p className="fs-14-vh c-manatee mt-4">{"Find your job"}</p>
                }
            </button>

            <div className="height-100 center">
                <div className="sp-line">
                
                </div>
            </div>

            <button id="where" onClick={() => onPressOption(SearchType.WHERE)} className={`search-option center flex-d-column ${optionDisplayed === SearchType.WHERE ? "sp-selected" : ""}`}>
                <p className="fs-16-vh c-text">{"Where ?"}</p>
                {
                    optionSelectedWhere.length > 0 ?
                    <div className="center mt-4">
                        <WhereIconsSeparated size="2vh" workPreference={where} />
                    </div>
                    : <p className="fs-14-vh c-manatee mt-2">{"Search a place"}</p>
                }
            </button>

            <div className="height-100 center">
                <div className="sp-line">
                
                </div>
            </div>
            
            <div className={`flex con-button-search ${optionDisplayed === SearchType.WHEN ? "sp-selected" : ""}`}>
                <button id="when" onClick={() => onPressOption(SearchType.WHEN)} className={`search-option center flex-d-column ov-search-option-3`}>
                    <p className="fs-16-vh c-text">{"When ?"}</p>
                    {
                        whenText ?
                        <p className="fs-16-vh c-text fw-600 mt-4">{whenText}</p>
                        : <p className="fs-14-vh c-manatee mt-4">{"Choose the date"}</p>
                    }
                </button>
                <div className="center ml-4">
                    <button onClick={onSearch} className="flex flex-ai-center dark-gray-bg pl-8 pr-8 button-search pointer">
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="c-white fs-16-vh" />
                        <p className="fs-16-vh c-white ml-6">{"Search"}</p>
                    </button>
                </div>
            </div>

            
            <ClickOutside onPress={onCloseOptions} exclude={["job", "where", "when"]}>
                { optionDisplayed === SearchType.JOB && <JobType /> }
                { optionDisplayed === SearchType.WHERE && <Where /> }  
                { optionDisplayed === SearchType.WHEN && <When /> }
            </ClickOutside>
            

        </div>    
    )
}

export default React.memo( SearchSelectors )