import axios, { AxiosRequestConfig } from "axios";
import { getUserCredentials } from "../../axiosUtils";


export const getCostPriceForTransaction = (
	otherUserID: string,
	type: 'gim' | 'gimmer',
	jobId: string,
	categoryName: string,
	duration: string,
): Promise<number> => {
	return new Promise((resolve, reject) => {
		getUserCredentials()
			.then((session) => {
				const token = session.idToken;
				const config: AxiosRequestConfig = {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
				axios
					.get(
						`${"https://96cte6673k.execute-api.us-west-1.amazonaws.com/prod"}/v2/user/transactions/price?otherUserId=${otherUserID}&type=${type}&jobId=${jobId}&categoryName=${categoryName}&time=${duration}`,
						config,
					)
					.then((response) => {
						resolve(response.data.price);
					})
					.catch((error: any) => {

						if (error && 'response' in error && error.response && 'status' in error.response && error.response.status && error.response.status === 500) {
							resolve(getCostPriceForTransaction(otherUserID, type, jobId, categoryName, duration))
							return
						}

						console.error(
							'Error getting price Transaction',
							error,
							`${"https://96cte6673k.execute-api.us-west-1.amazonaws.com/prod"}/v2/user/transactions/price?otherUserId=${otherUserID}&type=${type}&jobId=${jobId}&categoryName=${categoryName}&time=${duration}`,
						);
						if (error.response) {
							//console.error(error.response.data);
							reject(error);
						}
						//console.error('ERROR ' + JSON.stringify(error));
						//console.error(error.toJSON());
						reject(500);
					});
			})
			.catch((error) => {
				console.error('The user is not autorized getCostPriceForTransaction');
				//console.error(error);
				reject(null);
			});
	});
};


export const getCostPriceForTransactionPublic = (
	otherUserID: string,
	type: 'gim' | 'gimmer',
	jobId: string,
	categoryName: string,
	duration: string,
): Promise<number> => {
	return new Promise((resolve, reject) => {
		axios
			.get(
				`${"https://96cte6673k.execute-api.us-west-1.amazonaws.com/prod"}/v2/public/transactions/price?otherUserId=${otherUserID}&type=${type}&jobId=${jobId}&categoryName=${categoryName}&time=${duration}`,
			)
			.then((response) => {
				resolve(response.data.price);
			})
			.catch((error) => {
				console.error('Error getCostPriceForTransactionPublic');
				if (error.response) {
					console.error(error.response.data);
					reject(error);
				}
				console.error('ERROR ' + JSON.stringify(error));
				console.error(error.toJSON());
				reject(500);
			});
	});
};