import axios, { AxiosError, AxiosRequestConfig } from "axios"
import {
    adminApiUrl,
    getUserCredentials,
} from "../../axiosUtils"
import { CategoryJob } from "../../../../redux/slices/components/ManagerJobs/managerJobDataSlice"
import { S3JobsNamesFile } from "../../../staticVariables/staticVariables"

export const getAllJobsCategories = (jobData: boolean) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        const config: AxiosRequestConfig = { 
            params: { jobData }
        };
        axios.get(`${adminApiUrl}/v1/job-category`, config)
        .then((res) => { 
            resolve(res.data.jobCategories)
        })
        .catch((error: AxiosError) => { 
            console.log("Error: ", `${adminApiUrl}/v1/job-category`, error); 
            reject(error) 
        })
    })
} 

export const getAllJobsCategoriesPublic = () => {
    return new Promise((resolve: (value: CategoryJob[]) => void, reject) => {

            axios.get(`${adminApiUrl}/v1/job-category`)
            .then((res) => { 
                resolve(res.data.jobCategories)
            })
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category`, error); 
                reject(error) 
            })
    })
} 

export const createNewJobCategory = (body: any) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.post(`${adminApiUrl}/v1/job-category`, body, config)
            .then((res) => { 
                resolve(res.data)
            })
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const deleteJobCategory = (categoryId: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.delete(`${adminApiUrl}/v1/job-category/${categoryId}`, config)
            .then((res) => { 
                resolve(res.data)
            })
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

type getJobCategoryImagesLinksBody = {
    //categoryName: string,
    imgName: string
}
type GetJobCategoryImagesLinksResponse = { 
    imgKey: string
    photoKey: string 
    imgKey_100:  string
    imgKey_200: string
    imgKey_300: string
    imgKey_400: string
    imgKey_500: string
    imgKey_600: string
    imgKey_700: string
    imgKey_800: string
}

export const getJobCategoryImagesLinks = (
    body: getJobCategoryImagesLinksBody
) => {
    return new Promise((resolve: (value: GetJobCategoryImagesLinksResponse) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.post(`${adminApiUrl}/v1/job-category/images`, body, config)
            .then((res) => { 
                resolve(res.data)
            })
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category/images`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

type editJobCategoryBody = {
    categoryName: string,
    country: string,
    imgKey: string,
    photoKey: string
}
export const editJobCategory = (categoryId: string, body: editJobCategoryBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { 
                    Authorization: `Bearer ${token}`, 
                    'Cache-Control': 'no-cache'
                },
            };
            axios.patch(`${adminApiUrl}/v1/job-category/${categoryId}`, body, config)
            .then((res) => { 
                resolve(res.data)
            })
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category/${categoryId}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

type BodyJobNamesFile = {
    id: string
    names: {
        en: string
        es: string
        fr: string
    }
}

export const updateJobsNamesFile = (body: BodyJobNamesFile) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.put(`${adminApiUrl}/v1/job-category/names-file`, body, config)
            .then((response) => { 

                const url = response.data.presignedS3Url
                uploadJobsNamesFile(url, body)
                .then(() => {
                    resolve("OK")
                }).catch((error) => {
                    reject(error)
                })
            })
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category/names-file`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const uploadJobsNamesFile = (url: string, body: BodyJobNamesFile) => {
    return new Promise((resolve: (value: any) => void, reject) => { 
        const buckerUrl = S3JobsNamesFile
        const config: AxiosRequestConfig = { 
            headers: {  ContentType: 'application/json', 'Cache-Control': 'no-cache'  },
        };
        axios.get(buckerUrl, config)
        .then((res) => {
            const jobsNamesFile = res.data ?? {}
            console.log("before", JSON.parse( JSON.stringify(jobsNamesFile) ))
            const { id, names } = body
            jobsNamesFile[id] = names
            console.log("after", JSON.parse( JSON.stringify(jobsNamesFile) ))
            axios.put(url, jobsNamesFile, { headers: { ContentType: 'application/json' } })
            .then(() => {
                resolve("Ok")
            }).catch((error) => { 
                console.log("Error: ", url, error); 
                reject(error) 
            })
        }).catch((error) => { 
            console.log("Error: ", buckerUrl, error); 
            reject(error) 
        })
    })
}


export const deleteJobFromJobsNamesFile = (ids: string[]) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.put(`${adminApiUrl}/v1/job-category/names-file`, {}, config)
            .then((response) => { 

                const url = response.data.presignedS3Url
                uploadDeleteJobFromJobsNamesFile(url, ids)
                .then(() => {
                    resolve("OK")
                }).catch((error) => {
                    reject(error)
                })
            })
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category/names-file`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const uploadDeleteJobFromJobsNamesFile = (url: string, ids: string[]) => {
    return new Promise((resolve: (value: any) => void, reject) => { 
        const buckerUrl = S3JobsNamesFile
        const config: AxiosRequestConfig = { 
            headers: {  ContentType: 'application/json', 'Cache-Control': 'no-cache'  },
        };
        axios.get(buckerUrl, config)
        .then((res) => {
            const jobsNamesFile = res.data ?? {}
            console.log("before delete", JSON.parse( JSON.stringify(jobsNamesFile) ))

            for(const id of ids){
                delete jobsNamesFile[id]
            }

            console.log("after delete", JSON.parse( JSON.stringify(jobsNamesFile) ))
            axios.put(url, jobsNamesFile, { headers: { ContentType: 'application/json' } })
            .then(() => {
                resolve("Ok")
            }).catch((error) => { 
                //console.log("Error: ", url, error); 
                reject(error) 
            })

        }).catch((error) => { 
            console.log("Error: ", buckerUrl, error); 
            reject(error) 
        })
    })
}