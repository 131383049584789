import { useState, useEffect, memo, useRef } from 'react'
import {
    APIProvider, 
    Map, 
    Marker, 
    AdvancedMarker, 
    Pin,
    useMap,
    useMapsLibrary,
    useAdvancedMarkerRef,
    MapControl,
    ControlPosition, } from '@vis.gl/react-google-maps';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import "./MapWithSearchBar.css"
import SliderRadius from '../SliderRadius/SliderRadius';
import { Colors } from '../../../utils/colors/colors';
import MapView from '../Maps/MapView/MapView';
import { Position } from '../../../utils/maps/locations';


//import LoadingSpinner from '../../LoadingSpinner'

interface MapWithSearchBarProps {
    radius?: number, 
    longitude: number, 
    latitude: number
    showRadius: boolean
    onChangePositionMap: (newPosition: Position, radius?: number) => void
    height: string
}

const MapWithSearchBar = ({ radius = 0, longitude, latitude, showRadius, onChangePositionMap, height }: MapWithSearchBarProps) => {

    const [valueSlider, setValueSlider] = useState(radius)

    const onChange = (value: number) => {
        setValueSlider( value )
    }


    const on = (place: google.maps.places.PlaceResult | null) => {

    }
    
    return (
        <div className="p-relative" style={{ width: "100%", height }}>
            {
                showRadius &&
                <div className="p-absolute border-radius-3" style={{ right: "1rem", zIndex: 1, backgroundColor: "white", top: ".8vh" }}>
                    <SliderRadius
                        valueSlider={valueSlider}
                        onChange={onChange}
                        color={Colors.Gim}
                    />
                </div>
            }
            <MapView 
                initialLatitude={latitude}
                initialLongitude={longitude}
                radius={valueSlider}
                showRadius={showRadius}
                showSearchBar={true}
                onChangePositionMap={onChangePositionMap}
            />
        </div>
    )
}

// { loadingMap && <LoadingSpinner fullPage={false} /> }  
export default memo(MapWithSearchBar)