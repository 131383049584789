
import { Routes, Route } from "react-router-dom"
import MyProfileScreen from '../screens/SideBarScreens/UserScreens/MyProfileScreen/MyProfileScreen';
import MyHistoryScreen from '../screens/SideBarScreens/UserScreens/MyHistoryScreen/MyHistoryScreen';
import MySessionsScreen from '../screens/SideBarScreens/UserScreens/MySessionsScreen/MySessionsScreen';
import ManageBugsReportsScreen from '../screens/SideBarScreens/AdminScreens/ManageBugsReportsScreen/ManageBugsReportsScreen';
import ManageJobsScreen from '../screens/SideBarScreens/AdminScreens/ManageJobsScreen/ManageJobsScreen';
import ManageRolesScreen from '../screens/SideBarScreens/AdminScreens/ManageRolesScreen/ManageRolesScreen';
import ManageUsersScreen from '../screens/SideBarScreens/AdminScreens/ManageUsersScreen/ManageUsersScreen';
import VerifyPictureScreen from '../screens/SideBarScreens/AdminScreens/VerifyPictureScreen/VerifyPictureScreen';
import VerifyIdScreen from '../screens/SideBarScreens/AdminScreens/VerifyIdScreen/VerifyIdScreen';
import VerifyCertificateScreen from '../screens/SideBarScreens/AdminScreens/VerifyCertificateScreen/VerifyCertificateScreen';
import ManageReportsScreen from '../screens/SideBarScreens/AdminScreens/ManageReportsScreen/ManageReportsScreen';
import MyPurchasesScreen from '../screens/SideBarScreens/UserScreens/MyPurchasesScreen/MyPurchasesScreen';
import ErrorScreen from '../screens/ErrorScreen/ErrorScreen';
import { mainRouteAdmin } from '../utils/staticVariables/staticVariables';
import Error404 from '../screens/Error404/Error404';
import GeneralWrapperScreen from '../components/MainComponent/WrappersScreen/GeneralWrapperScreen/GeneralWrapperScreen';
import LargeManagerJobWrapperScreen from '../components/MainComponent/WrappersScreen/LargeManagerJobWrapperScreen/LargeManagerJobWrapperScreen';
import ManageAllOffers from '../screens/SideBarScreens/AdminScreens/ManageAllOffers/ManageAllOffers';
import BugReportScreen from '../screens/SideBarScreens/AdminScreens/ManageBugsReportsScreen/BugReportScreen/BugReportScreen';
import UserReportScreen from '../screens/SideBarScreens/AdminScreens/ManageReportsScreen/UserReportScreen/UserReportScreen';
import OfferScreen from '../screens/SideBarScreens/AdminScreens/ManageAllOffers/OfferScreen/OfferScreen';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import Login from '../screens/Login/Login';
import PublicRoute from "./PublicRoutes/PublicRoutes";
import DashboardScreen from "../screens/SideBarScreens/AdminScreens/DashboardScreen/DashboardScreen";
import PrivacyPolicyScreen from "../screens/DocumentsScreens/PrivacyPolicyScreen/PrivacyPolicyScreen";
import LegalNoticeScreen from "../screens/DocumentsScreens/LegalNoticeScreen/LegalNoticeScreen";
import TermConditionScreen from "../screens/DocumentsScreens/TermConditionsScreen/TermConditionScreen";
import RulesScreen from "../screens/SideBarScreens/AdminScreens/RulesScreen/RulesScreen";
import HistoryScreen from "../screens/SideBarScreens/UserScreens/MyHistoryScreen/HistoryScreen/HistoryScreen";
import DeletionReasonScreen from "../screens/SideBarScreens/UserScreens/MyProfileScreen/DeleteScreens/DeletionReasonScreen/DeletionReasonScreen";
import ConfirmDeletionScreen from "../screens/SideBarScreens/UserScreens/MyProfileScreen/DeleteScreens/ConfirmDeletionScreen/ConfirmDeletionScreen";
import MyMarketplaceScreen from "../screens/SideBarScreens/UserScreens/MyMarketplaceScreen/MyMarketplaceScreen";
import NameScreen from "../screens/SignUp/NameScreen/NameScreen";
import PasswordScreen from "../screens/SignUp/PasswordScreen/PasswordScreen";
import PhoneScreen from "../screens/SignUp/PhoneScreen/PhoneScreen";
import EmailScreen from "../screens/SignUp/EmailScreen/EmailScreen";
import ProfilePictureScreen from "../screens/SignUp/ProfilePictureScreen/ProfilePictureScreen";
import SelectProfileTypeScreen from "../screens/SignUp/SelectProfileTypeScreen/SelectProfileTypeScreen";
import WelcomeScreen from "../screens/SignUp/WelcomeScreen/WelcomeScreen";
import SelectSkillScreen from "../screens/SignUp/SkillGim/SelectSkillScreen/SelectSkillScreen";
import SelectLocationScreen from "../screens/SignUp/SkillGim/SelectLocationScreen/SelectLocationScreen";
import AddPicturesSkillScreen from "../screens/SignUp/SkillGim/AddPicturesSkillScreen/AddPicturesSkillScreen";
import ViewYourSkillsScreen from "../screens/SignUp/SkillGim/ViewYourSkillsScreen/ViewYourSkillsScreen";
import ConfirmEmailScreen from "../screens/SignUp/ConfirmEmail/ConfirmEmail";
import VideoIdScreen from "../screens/SignUp/VideoIdScreen/VideoIdScreen";
import IdSignUpBackScreen from "../screens/SignUp/IdsScreens/IdSignUpBackScreen/IdSignUpBackScreen";
import IdSignUpFrontScreen from "../screens/SignUp/IdsScreens/IdSignUpFrontScreen/IdSignUpFrontScreen";
import NotificationsScreen from "../screens/UserScreens/NotificationsScreen/NotificationsScreen";
import HelpScreen from "../screens/HelpScreens/HelpScreen/HelpScreen";
import AssistanceJobTaskScreen from "../screens/HelpScreens/AssistanceJobTaskScreen/AssistanceJobTaskScreen";
import MyReportsScreen from "../screens/HelpScreens/MyReportsScreen/MyReportsScreen";
import ReportBugScreen from "../screens/HelpScreens/ReportBugScreen/ReportBugScreen";
import ReportTransactionScreen from "../screens/HelpScreens/ReportTransactionScreen/ReportTransactionScreen";
import TableSimulatorScreen from "../screens/SideBarScreens/AdminScreens/ManageJobsScreen/TableSimulatorScreen/TableSimulatorScreen";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import SimulatorScreen from "../screens/SideBarScreens/AdminScreens/ManageJobsScreen/SimulatorScreen/SimulatorScreen";
import VerifyProductScreen from "../screens/SideBarScreens/AdminScreens/VerifyProductScreen/VerifyProductScreen";
import ProductsRequestScreen from "../screens/UserScreens/ProductsRequestScreen/ProductsRequestScreen";
import ManageProductsScreen from "../screens/SideBarScreens/AdminScreens/ManageProductsScreen/ManageProductsScreen";
import OpportunityScreen from "../screens/RequestProcess/OpportunityScreen/OpportunityScreen";

const AppRouter = () => {

    return (
            <Routes>

                <Route path={`/`} 
                    element={
                        <PublicRoute >
                            <HomeScreen isPrivate={false} />
                        </PublicRoute>
                    }
                />

                <Route path={`${mainRouteAdmin}/my-profile`} 
                    element={
                        <PrivateRoute >
                            <GeneralWrapperScreen RouteComponent={ MyProfileScreen } width="100%" />
                        </PrivateRoute>
                    }
                />

                <Route path={`${mainRouteAdmin}/my-profile/deletion-reason`} 
                    element={
                        <PrivateRoute >
                            <GeneralWrapperScreen RouteComponent={ DeletionReasonScreen } width="100%" />
                        </PrivateRoute>
                    }
                />

                <Route path={`${mainRouteAdmin}/my-profile/confirm-deletion`} 
                    element={
                        <PrivateRoute >
                            <GeneralWrapperScreen RouteComponent={ ConfirmDeletionScreen } width="100%" />
                        </PrivateRoute>
                    }
                />

                <Route path={`${mainRouteAdmin}/my-history`}  
                    element={
                        <PrivateRoute >
                            <GeneralWrapperScreen RouteComponent={ MyHistoryScreen } />
                        </PrivateRoute>
                    }
                />
                <Route path={`${mainRouteAdmin}/my-history/:id`}  
                    element={
                        <PrivateRoute >
                            <GeneralWrapperScreen RouteComponent={ HistoryScreen } />
                        </PrivateRoute>
                    }
                />
                <Route path={`${mainRouteAdmin}/my-sessions`}  
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ MySessionsScreen } />
                        </PrivateRoute>
                    }
                />
                <Route path={`${mainRouteAdmin}/my-shop`}  
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ MyMarketplaceScreen } />
                        </PrivateRoute>
                    }
                />

                <Route path={`${mainRouteAdmin}/my-purchases`}  
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ MyPurchasesScreen } />
                        </PrivateRoute>
                    }
                />

                <Route path={`${mainRouteAdmin}/dashboard`}  
                    element={
                        <PrivateRoute>
                            <LargeManagerJobWrapperScreen RouteComponent={ DashboardScreen } />
                        </PrivateRoute>
                    }
                />

                <Route path={`${mainRouteAdmin}/manage-jobs`}  
                    element={
                        <PrivateRoute>
                            <LargeManagerJobWrapperScreen RouteComponent={ ManageJobsScreen } />
                        </PrivateRoute>
                    }
                />

                <Route path={`${mainRouteAdmin}/simulator`}  
                    element={
                        <PrivateRoute>
                            <LargeManagerJobWrapperScreen RouteComponent={ SimulatorScreen } />
                        </PrivateRoute>
                    }
                />

                <Route path={`${mainRouteAdmin}/manage-roles`}  
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ ManageRolesScreen } /> 
                        </PrivateRoute>
                    }
                />
                <Route path={`${mainRouteAdmin}/manage-products`}   
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ ManageProductsScreen } /> 
                        </PrivateRoute>
                    }
                />
                <Route path={`${mainRouteAdmin}/request-product`}   
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ ProductsRequestScreen } /> 
                        </PrivateRoute>
                    }
                />
                <Route path={`${mainRouteAdmin}/manage-users`}   
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ ManageUsersScreen } /> 
                        </PrivateRoute>
                    }
                />
                <Route path={`${mainRouteAdmin}/manage-offers`}   
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ ManageAllOffers } />
                        </PrivateRoute>
                    }
                />
                <Route path="manage-offers/:id" 
                    element={ 
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ OfferScreen } /> 
                        </PrivateRoute>
                    } 
                />


                <Route path={`${mainRouteAdmin}/verify-profile-picture`}   
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ VerifyPictureScreen } />
                        </PrivateRoute>
                    }
                />
                <Route path={`${mainRouteAdmin}/verify-id`}  
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ VerifyIdScreen } />
                        </PrivateRoute>
                    }
                />
                <Route path={`${mainRouteAdmin}/verify-certificate`}  
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ VerifyCertificateScreen } /> 
                        </PrivateRoute>
                    }
                />

                <Route path={`${mainRouteAdmin}/verify-product`}  
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ VerifyProductScreen } /> 
                        </PrivateRoute>
                    }
                />

                <Route path={`${mainRouteAdmin}/manage-reports`}  
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ ManageReportsScreen } />
                        </PrivateRoute>
                    }
                />

                <Route path="manage-reports/:id" 
                    element={ 
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ UserReportScreen } /> 
                        </PrivateRoute>
                    } 
                />

                <Route path="manage-bug-reports" 
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ ManageBugsReportsScreen } />
                        </PrivateRoute>
                    } 
                />

                <Route path="manage-bug-reports/:id" 
                    element={ 
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ BugReportScreen } />
                        </PrivateRoute>
                     } 
                />

                <Route path="rules" 
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ RulesScreen } />
                        </PrivateRoute>
                    } 
                />

                {
                    //                <Route path={mainRouteAdmin} element={ <HomeScreen lastUrlPath={previousRoute.current}/> } />
                }
                <Route path={`${mainRouteAdmin}/login`} 
                    element={ 
                        <PublicRoute>
                            <Login />
                        </PublicRoute>
                    } 
                 />

                <Route path={`${mainRouteAdmin}/name`} 
                    element={ 
                        <PublicRoute percentageBar={2}>
                            <NameScreen />
                        </PublicRoute>
                    } 
                 />

                <Route path={`${mainRouteAdmin}/name-federative`} 
                    element={ 
                        <PrivateRoute percentageBar={2}>
                            <NameScreen isPrivate={true} />
                        </PrivateRoute>
                    } 
                 />

                <Route path={`${mainRouteAdmin}/password`} 
                    element={ 
                        <PublicRoute percentageBar={10}>
                            <PasswordScreen />
                        </PublicRoute>
                    } 
                 />

                <Route path={`${mainRouteAdmin}/phone-number`} 
                    element={ 
                        <PublicRoute percentageBar={20}>
                            <PhoneScreen />
                        </PublicRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/phone-number-federative`} 
                    element={ 
                        <PrivateRoute percentageBar={20}>
                            <PhoneScreen isPrivate={true} />
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/email`} 
                    element={ 
                        <PublicRoute percentageBar={30}>
                            <EmailScreen />
                        </PublicRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/confirm-email`} 
                    element={ 
                        <PublicRoute percentageBar={35}>
                            <ConfirmEmailScreen />
                        </PublicRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/profile-picture`} 
                    element={ 
                        <PrivateRoute percentageBar={40}>
                            <ProfilePictureScreen />
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/id-front`} 
                    element={ 
                        <PrivateRoute percentageBar={50}>
                            <IdSignUpFrontScreen />
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/id-back`} 
                    element={ 
                        <PrivateRoute percentageBar={53}>
                            <IdSignUpBackScreen />
                        </PrivateRoute>
                    } 
                />


                <Route path={`${mainRouteAdmin}/video-id`} 
                    element={ 
                        <PrivateRoute percentageBar={58}>
                            <VideoIdScreen />
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/select-profile`} 
                    element={ 
                        <PrivateRoute percentageBar={60}>
                            <SelectProfileTypeScreen />
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/gim-skill/select-skill`} 
                    element={ 
                        <PrivateRoute percentageBar={70}>
                            <SelectSkillScreen />
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/gim-skill/select-location`} 
                    element={ 
                        <PrivateRoute percentageBar={80}>
                            <SelectLocationScreen />
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/gim-skill/add-pictures`} 
                    element={ 
                        <PrivateRoute percentageBar={90}>
                            <AddPicturesSkillScreen />
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/gim-skill/view-skills`} 
                    element={ 
                        <PrivateRoute percentageBar={95}>
                            <ViewYourSkillsScreen />
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/welcome`} 
                    element={ 
                        <PrivateRoute percentageBar={100}>
                            <WelcomeScreen />
                        </PrivateRoute>
                    } 
                />


                <Route path={`${mainRouteAdmin}/notifications`} 
                    element={
                        <PrivateRoute>
                            <NotificationsScreen />
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/help`} 
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ HelpScreen } /> 
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/report-bug`} 
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ ReportBugScreen } /> 
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/assistance-job-task`} 
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ AssistanceJobTaskScreen } /> 
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/my-reports`} 
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ MyReportsScreen } /> 
                        </PrivateRoute>
                    } 
                />

                <Route path={`report-transaction/:id`} 
                    element={
                        <PrivateRoute>
                            <GeneralWrapperScreen RouteComponent={ ReportTransactionScreen } /> 
                        </PrivateRoute>
                    } 
                />

                <Route path={`${mainRouteAdmin}/table-simulator/:id`} 
                    element={ 
                        <PrivateRoute>
                            <TableSimulatorScreen />
                        </PrivateRoute>
                    } 
                />

                <Route path="opportunity/:id" 
                    element={
                        <PublicRoute>
                            <OpportunityScreen />
                        </PublicRoute>
                    } 
                />


                <Route path={`${mainRouteAdmin}/stage-react`} element={ <ErrorScreen errorMessage={`Stage: ${process.env.REACT_APP_USER_FILES} ${process.env.REACT_APP_STAGE} ${process.env.REACT_APP_API}`} loginAgain={false} /> } />
                <Route path={`${mainRouteAdmin}/403`} element={ <ErrorScreen errorMessage={"You dont have access to this resource 403"} loginAgain={false} /> } />
                <Route path={`${mainRouteAdmin}/error-device`} element={ <ErrorScreen errorMessage={"You already have a session in this browser"} loginAgain={false} /> } />
                <Route path={`${mainRouteAdmin}/error-session`} element={ <ErrorScreen errorMessage={"You already have a session opened in another device"} loginAgain={true} /> } />
                <Route path={`${mainRouteAdmin}/error-auth`} element={ <ErrorScreen errorMessage={"An error ocurred in the login, contact support or login again"} loginAgain={true} /> } />
            

                <Route path={`${mainRouteAdmin}/privacy-policy`} element={ <PrivacyPolicyScreen /> } />
                <Route path={`${mainRouteAdmin}/legal-notice`} element={ <LegalNoticeScreen /> } />
                <Route path={`${mainRouteAdmin}/terms-and-conditions`} element={ <TermConditionScreen /> } />

                {
                    // <Route path="*" element={ <HomeScreen lastUrlPath={`${mainRouteAdmin}${previousRoute.current}`}/> } />
                }

                <Route path="*" element={ <Error404 />} />
            </Routes>
        )
}

export default AppRouter


/*


*/