import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import  "./JobType.css"
import CardJobType from './CardJobType/CardJobType'
import { setJobSearch, setOptionDisplayedSearch } from '../../../../redux/slices/components/HomeOffers/homeOffersSlice'
import { JobCategoryJob, JobCategoryJobWhole } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'

const JobType = () => {

    const dispatch = useAppDispatch()

    const categories = useSelector((state: RootState) => state.managerJobData.categoryJobs)
    const jobs = categories.flatMap(category => category.jobs.map((job) => ({ ...job, categoryId: category.id})));

    const onPress = useCallback(
        (jobId: string) => {
            dispatch( setJobSearch(jobId) )
            dispatch( setOptionDisplayedSearch(undefined) )
        },
        [],
    )
    

    return (
        <div className="con-jobs">
            <p className="fs-18-vh color-text">{"Find your job"}</p>
            <div className="flex flex-jc-sb flex-wrap">
                {
                    jobs.map((job) => {
                        return (
                            <CardJobType 
                                key={job.id}
                                job={job}
                                onPress={onPress}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default React.memo( JobType )