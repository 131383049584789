import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useEffect, useMemo } from "react"
import { FaCheck } from 'react-icons/fa'
import { setDetailedOfferReducer } from "../../../../redux/slices/components/ManageOffers/manageDetailedOfferSlice"
import { Offer, updateSeenOfferReducer } from '../../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { useAppDispatch } from '../../../../redux/store/store'
import { updateSeenOffer } from "../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions"
import { getProfilePictureByUserId } from "../../../../utils/requestsUtils/functionsRequests/user/userFunctions"
import { GetOfferType, UserInfoCache, UserInfoCacheType, getMinimalUserInfoData } from "../../../../utils/staticVariables/staticVariables"
import "./OfferRow.css"
import WhereIcons from './WhereIcons/WhereIcons'
import { useNavigate } from "react-router-dom"
import moment from "moment"
import useGetJobName from "../../../hooks/useGetJobName/useGetJobName"
import { orderSchedulesByStartDate } from "../../../../utils/dates/dates"
import { capitalizeAllWords } from "../../../../utils/strings/strings"

export interface OfferRowProps {
    offer: Offer
}

const OfferRow = ({ offer }: OfferRowProps) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const soonerDate = useMemo(() => {
        const firstDate = orderSchedulesByStartDate(offer.detailsSchedule)
        if(firstDate?.length > 0)
            return firstDate[0]
        else
            return { start: "", end: "", id: "" }
    }, [offer.detailsSchedule])

    const [userName, setUserName] = useState<string>("")
    const [userImg, setUserImg] = useState<string>("")

    const jobName = useGetJobName(offer.jobId)

    useEffect(() => {
        //const user = UserInfoCache.userNameCache.get(offer.userId)

        //if(user?.name){
            //setUserName(user.name)
        //}else{
            getMinimalUserInfoData(offer.userId)
            .then((userInfo: UserInfoCacheType) => {
                setUserName(userInfo.name)
            })
        //}

        getProfilePictureByUserId(offer.userId, "small")
        .then((imgUrl) => {
            setUserImg(imgUrl)
        }).catch(() => {})


      return () => {

      }
    }, [])
    

    const clickOnOffer = () => {
        navigate(`/manage-offers/${offer.id}`)
        updateSeenOffer(offer.id, offer.type)
        .then(() => {
            dispatch(updateSeenOfferReducer({ id: offer.id}))
        }).catch((error) => {
            console.log("error", error)
        })
        dispatch(setDetailedOfferReducer({offer, imgUrl: userImg}))
    }

    return (
        <div onClick={clickOnOffer} className="container-row-offer flex mt-8 mb-8 pointer ">
            
            {
                // Seen circle
                !offer.seen &&
                <div className="con-circle-saw center">
                    <div className="circle-saw circle"></div>
                </div>
            }

            {
                // Offer Id
            }
            <div className="offer-item1 height-100 center">
                <p title={offer.id} className="id-offer fs-14 fw-500">{offer.id}</p>
            </div>

            {
                // Title
            }
            <div className="offer-item2 height-100 flex flex-ai-center">
                <p className="fs-14 fw-500">{jobName}</p>
            </div>

            {
                // Type
            }
            <div className="offer-item3 height-100 flex flex-ai-center">
                <button className={`btn-type pt-6 pb-6 fw-600 fs-14 c-white type-color-${offer.type}`}>
                    {GetOfferType(offer.type)}
                </button>
            </div>

            {
                // Creation Date
            }
            <div className="offer-item4 height-100 flex flex-ai-center">
                <p className="fs-14 fw-600">{moment(offer.timestamp).format('D MMM YYYY')}</p>
            </div>

            {
                // Owner Of The Offer
            }
            <div className="offer-item5 height-100 flex flex-ai-center pr-4">
                <div className="img-circular-row circle" style={{ backgroundImage: `url("${userImg}")` }}></div>
                <p title={userName} className="fs-14 fw-500 ml-8 text-3-points">{userName}</p>
            </div>

            {
                // Where
            }
            <div className="offer-item6 height-100 flex flex-ai-center">
                <WhereIcons {...offer.workPreference} />
            </div>

            {
                // When
            }
            <div className="offer-item7 height-100 flex flex-d-column flex-jc-c">
                <p className="fs-14 fw-500">{capitalizeAllWords(moment(soonerDate.start).format("dddd, MMMM Do"))}</p>
                <p className="fs-14 fw-500">{`${moment(soonerDate.start).format("HH:mm")} - ${moment(soonerDate.end).format("HH:mm")}`}</p>
            </div>

            {
                // Custom Image
            }
            <div className="offer-item8 height-100 center">
                <FontAwesomeIcon icon={solid("check")} className="fs-16" />
            </div>

        </div>
    )
}

export default React.memo( OfferRow )