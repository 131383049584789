import React, { useCallback } from 'react'
import "./ContainerOfferCards.css"
import OfferCard from '../OfferCard/OfferCard'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import { useNavigate } from 'react-router-dom'

type ContainerOfferCardsProps = {
}

const ContainerOfferCards = ({  }: ContainerOfferCardsProps) => {

    const navigate = useNavigate()

    const offers = useSelector((state: RootState) => state.homeOffers.offersToShow)

    const onPress = useCallback(
        (id: string) => {
            navigate(`/opportunity/${id}`)
        },
        [],
    )
    

    return (
        <div className="con-offer-cards flex mt-20">
          {
              offers.map((offer) => {
                  return (
                    <OfferCard onPress={onPress} key={offer.id} id={offer.id} />
                  )
              })
          }
        </div>
    )
}

export default React.memo( ContainerOfferCards )