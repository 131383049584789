import React from 'react'
import "./OpportunityButton.css"
import { TypeOffers } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'

type OpportunityButtonProps = {
    onPress: () => void
    type: TypeOffers
}

const OpportunityButton = ({ type, onPress }: OpportunityButtonProps) => {
    return (
        <button onClick={onPress} className={`opportunity-btn border-radius-4 width-100 c-white fw-600 fs-22 pointer ${type === "Task" ? "gim-bg" : "gimmer-bg"}`}>
            {type === "Task" ? "Take the job" : "Hire"}
        </button>
    )
}

export default React.memo( OpportunityButton )