import React, { useCallback } from 'react'
import CalendarComponent from '../../../UIcomponents/CalendarComponent/CalendarComponent'
import "./When.css"
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { setWhenSearch } from '../../../../redux/slices/components/HomeOffers/homeOffersSlice'

const When = () => {

    const dispatch = useAppDispatch()

    const type = useSelector((state: RootState) => state.homeOffers.type)
    const when = useSelector((state: RootState) => state.homeOffers.when)

    const onPressCalendar = useCallback(
        (date: moment.Moment) => {
            dispatch( setWhenSearch({ iso: date.toISOString(), milliseconds: date.valueOf() }) )    
        },
        [],
    )
    

    return (
        <div id="con-when" className="con-calendar">
            <CalendarComponent 
                numberOfMonths={2}
                onPress={onPressCalendar}
                type={type}
                when={when}
            />
        </div>
    )
}

export default React.memo( When )