import React, { useState } from 'react'
import { Colors } from '../../../utils/colors/colors'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import { GimGimmer } from '../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'

type SideBarSelector = "left" | "right"

type TwoSelectorBarProps = {
    initialSide: SideBarSelector
}

const TwoSelectorBarGimGimmer = ({ initialSide }: TwoSelectorBarProps) => {

    const navigate = useNavigate()

    const [sideSelected, setSideSelected] = useState<SideBarSelector>(initialSide)

    const page = useSelector((state: RootState) => state.homeOffers.page)
    const limit = useSelector((state: RootState) => state.homeOffers.limit)
    const jobId = useSelector((state: RootState) => state.homeOffers.jobId)
    const where = useSelector((state: RootState) => state.homeOffers.where)
    const startTime = useSelector((state: RootState) => state.homeOffers.when?.startTime)
    const endTime = useSelector((state: RootState) => state.homeOffers.when?.endTime)
    const optionSelectedWhere = useSelector((state: RootState) => state.homeOffers.optionSelectedWhere)

    const leftSideSelected = sideSelected === "left" 

    const onChange = (side: SideBarSelector) => {

        const params: any = {
            page: page.toString(),
            limit: limit.toString(),
            type: side === "left" ? "Gim" : "Gimmer",
        }

        if(jobId)
            params["jobId"] = jobId?.toString()

        if( startTime )
            params["startTime"] = startTime?.iso

        if( endTime )
            params["endTime"] = endTime?.iso

        if( where.outside && optionSelectedWhere.includes("outside") )
            params["outside"] = JSON.stringify(where.outside)

        if( where.inside && optionSelectedWhere.includes("inside") )
            params["inside"] = JSON.stringify(where.inside)

        if( optionSelectedWhere.includes("remote"))
            params["remote"] = JSON.stringify(true)

        const searchParams = createSearchParams(params);
        setSideSelected(side)
        navigate({
            pathname: '/',
            search: searchParams.toString(),
        });
    }

    const clickBar = (newSide: SideBarSelector) => {
        onChange(newSide)
        setSideSelected(newSide)
    }


    return (
        <div className="flex flex-jc-end mt-30 ml-10" style={{ width: "20rem", height: "3vh" }}>
            <div 
                className={`width-50 gallery-bg fs-16-vh center ${leftSideSelected ? "c-white" : "pointer"}`}
                style={{
                    backgroundColor: leftSideSelected ? Colors.Gim : "white"
                }}
                onClick={() => {
                    if(!leftSideSelected)
                        clickBar("left")
                }}
            >
                Gim
            </div>

            <div 
                className={`width-50 gallery-bg fs-16-vh center ${!leftSideSelected ? "c-white" : "pointer"}`}
                style={{
                    backgroundColor: !leftSideSelected ? Colors.Gimmer : "white"
                }}
                onClick={() => {
                    if(leftSideSelected)
                        clickBar("right")
                }}
            >
                Gimmer
            </div>
        </div>
    )
}

export default React.memo( TwoSelectorBarGimGimmer )